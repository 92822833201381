import styled from 'styled-components'; 
import { mediumgrey, white } from '../config/theme';
import {IoCloseOutline} from "react-icons/io5";
import Avatar from '../../components/assets/Avatar.jpg';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    position: fixed;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ModalWrapper = styled.div`
    width: 60vw;
    height: 70vh;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background-color: ${props => white};
    color: ${props => mediumgrey};
    display: flex;
    position: relative;
    /*margin-top: 12%;
    top: 25%;
    left: 20%;*/
    z-index: 21;
    border-radius: 4px;
    padding: 30px;

    @media screen and (max-width:768px){
        
    }
`


export const IconClose = styled(IoCloseOutline)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`
export const AnimatedDiv = styled.div``

export const PicAuthor = styled.div`
    height: 150px;
    width: 150px;
    background: url(${props => Avatar}) no-repeat center /cover;
    border-radius: 50%;
    margin: 30px 30px 30px 5px;
    box-sizing: border-box;
    flex: 0 0 150px;
    @media screen and (max-width:768px){
        margin: 20px 5px;
    }
`