import styled from 'styled-components';
import { RiHeart3Line, RiHeart3Fill} from 'react-icons/ri';
import {BiShareAlt} from 'react-icons/bi'
import { mediumgrey, primaryColor, secondaryColor} from '../config/theme';

export const UpperCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    margin: 20px 0px;
`

export const CardWrapper = styled.div`
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 20px 20px;

    @media screen and (max-width:768px){
        width:90%;
        margin: 15px 10px;
        flex-direction: column;
        box-sizing: border-box;
    }
`

export const CardImage = styled.img`
    width: 100%;
    height: 20vw;
    object-fit: cover;
    object-position: 25% 50%;

    @media screen and (max-width:768px){
        width:100%;
        height: 30vh;
    }
`

export const TextWrapper = styled.div`
    display:flex;
    padding:20px;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
`

export const H3text = styled.h3`
    color: ${props => secondaryColor};
    padding: 0px;
    margin: 0px;
`
export const Category = styled.p`
    font-size:12px;
    padding: 0px;
    margin-top: 2px;
    text-transform: uppercase;
    color: ${props => mediumgrey};
`
export const DescriptionWrapper = styled.div`
    box-sizing: border-box;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ParDescription = styled.p`
    margin-top: 1px;
    font-size: 12px;
    line-height: 20px;
`
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
`
export const IconWrapper = styled.div`
    padding-right:10px;
    display: flex;
`

export const HeartIconFull = styled(RiHeart3Fill)`
    color: ${props => mediumgrey};
    margin: 0px 3px;
    &:hover{
        color: ${props => primaryColor};
        cursor: pointer;
    }
`
export const HeartIconEmpty = styled(RiHeart3Line)`
    color: ${props => mediumgrey};
    margin: 0px 3px;
    &:hover{
        color: ${props => primaryColor};
        cursor: pointer;
    }
`
export const ShareIcon = styled(BiShareAlt)`
    color: ${props => mediumgrey};
    width: 30px;
    height: 30px;
    margin: 0px 3px;
    &:hover{
        color: ${props => primaryColor};
        cursor: pointer;
    }
`
