import React from 'react';
import { PicAuthor,ModalWrapper, IconClose, Background } from './modalElements';
import { useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {useSpring, animated} from 'react-spring';

export const Modal = ({showModal, setShowModal}) => {

    const modalRef = useRef()
    const closeModal = (e) =>{
        if (modalRef.current === e.target){
            setShowModal(false);
        }
    }

    const animation = useSpring({
        config:{
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    })

    const keyPress = useCallback(
        e => {
          if (e.key === 'Escape' && showModal) {
            setShowModal(false);
            console.log('I pressed');
          }
        },
        [setShowModal, showModal]
      );
    
      useEffect(
        () => {
          document.addEventListener('keydown', keyPress);
          return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
      );

      const params = useParams();
      const profileId = params.id;


    return (
        <>
        {showModal 
        ? (
            <Background  ref={modalRef} onClick={closeModal}>
                <animated.div style={animation}>
                    <ModalWrapper showModal={showModal}>
                        <IconClose aria-label='close modal' onClick={()=>setShowModal(prev => !prev)} />
                        <h1>Edita tu perfil</h1>
                        {profileId.pic
                ?<PicAuthor style= {{ background: `url(${profileId.pic}) no-repeat center /cover`}}/>
                :<PicAuthor />}
                       
                    </ModalWrapper>
                </animated.div>
            </Background>
        )
        : null}
        </>
    )
};

export default Modal;