import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoginPage from './pages/login';
import HomePage from './pages/index'; 
import SignUpPage from './pages/signup';
import MyWeekPage from './pages/myweek';
import RecipePage from './pages/recipe';
import ProfilePage from './pages/myprofile';
import FavouritesPage from './pages/favoritas';
import ShoppingListPage from './pages/listacompra';
//import ForgotPassword from './pages/forgotpassword';

const Routes = ({ userData }) => (
    <Router>
      <Navbar />
      <Switch>
      
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignUpPage} />
        <ProtectedRoute path="/myprofile/:id" component={ProfilePage} />
        <ProtectedRoute path="/myweek/:id" component={MyWeekPage} />
        <ProtectedRoute path="/recipe/:id" component={RecipePage} />
        <ProtectedRoute path="/favoritas/:id" component={FavouritesPage} />
        <ProtectedRoute path="/listacompra/:id" component={ShoppingListPage} />
        {/* <Route exact path="/forgot/password" component={ForgotPassword} /> */}
        <Route exact path="/" component={HomePage} />
        <Redirect to="/"/>
      </Switch>
      <Footer />
    </Router>
  )
  
  export default Routes;