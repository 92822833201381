import {ProfileWrapper,
    ProfileHeader,
    PicAuthor,
    InfoProfile,
    H3Profile,
    ParProfile,
    ButtonWrapperProfile,
    EditProfileButton,
    CreateNewRecipe,
} from './layouts/profilelayout'
import Card from '../components/Card/index';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, } from 'react';
import { getObjectById } from '../services/db';
import Modal from '../components/Modal';
import ModalNewRecipe from '../components/ModalNewRecipe';
import FavoritesCard from '../components/FavoritesCard';
import { LinkProfile } from '.././pages/layouts/recipelayout';

function FavouritesPage() {

//Modal
const [showModal, setShowModal] = useState(false);

const openModal = () => {
    setShowModal(prev => !prev)
}

const [showModalNewRecipe, setShowModalNewRecipe] = useState(false);
const openModalNewRecipe = () => {
    setShowModalNewRecipe(prev => !prev)
}

const params = useParams();
const profileId = params.id;
const dispatch = useDispatch();

const [profile,setProfile]= useState(null);
const getProfile = async () => {
    const result = await getObjectById('profiles',profileId);
    const {data,success} = result;
    if(success){
        setProfile(data);
    }
}

useEffect(()=> {getProfile()},[])
const user = useSelector(state => state.user)
if (!user){
return <div>Loading</div>
}
const {lastname,name,pic,id,description} = user;

return(
    
<div>
    <Modal showModal={showModal} setShowModal={setShowModal} profileId={user.pic}/>
    <ModalNewRecipe showModalNewRecipe={showModalNewRecipe} setShowModalNewRecipe={setShowModalNewRecipe} />
    <ProfileHeader>
        <ProfileWrapper>
            <LinkProfile to="/myprofile/:id">
                    {pic
                    ?<PicAuthor style= {{ background: `url(${pic}) no-repeat center /cover`}}/>
                    :<PicAuthor />}
                </LinkProfile>
            <InfoProfile>
                <H3Profile>{name} {lastname}</H3Profile>
                <ParProfile>{description}</ParProfile>
                <ButtonWrapperProfile>
                    <EditProfileButton onClick={openModal}>Editar perfil</EditProfileButton>
                    <EditProfileButton to='/myprofile/:id'>Perfil</EditProfileButton>
                    <CreateNewRecipe onClick={openModalNewRecipe}></CreateNewRecipe>
                </ButtonWrapperProfile>                
            </InfoProfile>
        </ProfileWrapper>
    </ProfileHeader>
     
        <FavoritesCard />
</div>    
)
};

export default FavouritesPage;