import { getObjectById} from '../services/db';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RecipePic from '../components/assets/RecipePic.png';
import HeartIcon from '../components/HeartIcon';
import {
    RecipeBody,
    Background,
    RecipeBackgroundGreen,
    RecipeBackgroundGrey,
    RecipeWrapper,
    RecipeInfo,
    TextWrapper,
    AuthorInfo,
    PicAuthor,
    NameAuthor,
    ImageWrapper,
    Category,    
    RecipeTitle,
    Description,
    DescriptionText,
    TimeRecipe,
    TimesP,
    CategoryWrapper,
    CategoryButton,
    Linea,
    IconWrapper,
    ShareIcon,
    LastWrapper,
    RecipeInstructions,
    ImgDescription,
    IngredientsWrapper,
    IngredientP,
    InstructionsWrapper,
    LinkProfile
    } from './layouts/recipelayout'

function RecipePage() {

    const params = useParams();
    const recipeId = params.id;

    const [recipe,setRecipe]= useState(null);
    const getRecipes = async () => {
        const result = await getObjectById('recipes',recipeId);
        const {data,success} = result;
        if(success){
            setRecipe(data);
        }
    }

    useEffect(()=> {getRecipes()},[])

    const user = useSelector(state => state.user)
  
    if (!recipe){
        return <div>Loading</div>
    } 
    const {id, name, pic, description, dificulty, elaboration, cook, ingredients, instructions, category, author_id} = recipe;
    return(
        <div>
        
            <RecipeBody>

                <RecipeWrapper key={id}>
                    <RecipeInfo>
                        <TextWrapper>
                            <AuthorInfo>
                                <LinkProfile to="/myprofile/:id">
                                    {pic
                                    ?<PicAuthor style= {{ background: `url(${user.pic}) no-repeat center /cover`}}/>
                                    :<PicAuthor />}
                                    </LinkProfile>
                                    <NameAuthor>{user.name} {user.lastname}</NameAuthor>
                                
                            </AuthorInfo>
                        <Category>{category[0]}</Category>
                        <RecipeTitle>{name}</RecipeTitle>
                        <Linea />
                        <Description>
                            <DescriptionText>{description}</DescriptionText>
                            <TimeRecipe>
                                <TimesP>Dificultad: {dificulty}</TimesP>
                                <TimesP>Tiempo total: {elaboration + cook}</TimesP>
                                <TimesP>Elaboración: {elaboration}</TimesP>
                                <TimesP>Cocción: {cook}</TimesP>
                                {/* <TimesP>Reposo{{wait}? {wait}: ''}</TimesP> */}
                            </TimeRecipe>
                        </Description>
                        
                        <Category>Categorias</Category>
                            <CategoryWrapper>

                            {category.map((cat)=>{
                                return <CategoryButton>{cat}</CategoryButton>
                            })
                            }
                            </CategoryWrapper>

                            <LastWrapper>
                                <p>Created on 26/4/2021</p>
                                <IconWrapper>
                                    <HeartIcon recipeId={recipeId}  />
                                    <ShareIcon />
                                </IconWrapper>
                            </LastWrapper>
                        </TextWrapper>
                        {/* <ImageWrapper />*/}
                        <ImageWrapper style= {{ background: `url(${pic}) no-repeat center /cover`}} />
                    </RecipeInfo>

                    


                    <InstructionsWrapper> 
                        <RecipeInstructions>
                            <Category>Instrucciones</Category>
                            
                            {/* {instructions.map((instruction)=>{
                                return (
                                    <Description>{instruction}</Description>
                                )
                            })} */}
                            
                            <ImgDescription src={RecipePic}/>
                            <Description>1. Sagittis, eu pretium massa quisque cursus augue massa cursus. Sed quisque velit, auctor at lobortis hac tincidunt sodales id. Elit interdum vel nisi, in enim sagittis at. Netus sagittis eleifend aliquet urna quis.</Description>
                            <ImgDescription src={RecipePic}/>
                            <ImgDescription src={RecipePic}/>
                            <Description>2. Sagittis, eu pretium massa quisque cursus augue massa cursus. Sed quisque velit, auctor at lobortis hac tincidunt sodales id. Elit interdum vel nisi, in enim sagittis at. Netus sagittis eleifend aliquet urna quis. Sagittis, eu pretium massa quisque cursus augue massa cursus. Sed quisque velit, auctor at lobortis hac tincidunt sodales id. Elit interdum vel nisi, in enim sagittis at. Netus sagittis eleifend aliquet urna quis.
                            </Description>
                        </RecipeInstructions>    
                        
                        <IngredientsWrapper>
                            <Category>Ingredients</Category>
                            {ingredients.map((ingredient,)=>{
                                    return (
                                        <div>
                                            <IngredientP>{ingredient}</IngredientP>
                                            <Linea />
                                        </div>
                                    )
                                })}
                        </IngredientsWrapper>        
                    </InstructionsWrapper>   
                    
            
                </RecipeWrapper>
                <Background>
                    <RecipeBackgroundGrey />
                    <RecipeBackgroundGreen />
                    
                </Background>        
                
            </RecipeBody>
    </div>
    )
};

export default RecipePage;