import { getObjectById, updateObject } from '../../services/db';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/user/userActions';
import { HeartIconEmpty, HeartIconFull, HeartWrapper } from './HeartIconElements';


const HeartIcon = ({recipeId}) => {
    const dispatch = useDispatch();

    const [recipe,setRecipe]= useState(null);
    const getRecipes = async () => {
        const result = await getObjectById('recipes',recipeId);
        const {data,success} = result;
        if(success){
            setRecipe(data);
        }
    }

    useEffect(()=> {getRecipes()},[])

    const user = useSelector(state => state.user)
    const isFavourite = user && user.fav_recipes && user.fav_recipes.find((favourite) => favourite.id === recipeId);
    
    const saveToFavs = async () =>{
        if (user){
            debugger;
            const isFavourite = user.fav_recipes && user.fav_recipes.find((favourite) => favourite.id === recipeId);
            const newFavourite = isFavourite
            ? user.fav_recipes.filter((favourite) => favourite.id !== recipeId)
            : [...(user.fav_recipes || []), {id : recipe.id, name: recipe.name, description: recipe.description, cat: recipe.category, pic: recipe.pic}];
            
            const userToSave = {
                ...user,
                fav_recipes: newFavourite
            }
            
            const {success} = await updateObject('profiles', userToSave, user.id)
            if (success) {
                dispatch(setUser( userToSave ));
            }
        }else{
            
        }
    }

    return (
    <HeartWrapper>
        {isFavourite
                ? <HeartIconFull onClick={saveToFavs}/> 
                : <HeartIconEmpty onClick={saveToFavs}/>}
    </HeartWrapper>
    )
}

export default HeartIcon;