import { LoginWrapper,ImageLogin,FormWrapper,ParLogin,H3Login,LabelLogin,FormLogin,InputLogin,
         ForgotPass,ButtonLogin,ButtonGoogle,PasswordWrapper,Checkbox,NotMember,NotMemberLink,ErrorMessage
        } from '../pages/layouts/loginlayout.js';
import { useHistory } from 'react-router-dom';
import GoogleIcon from '../components/assets/Google_icon.png';
import {useState} from 'react';
import { login } from '../services/auth.js';


const LoginPage = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({ email: '', password: ''});

    const ERROR_MESSAGES ={
        'auth/wrong-password': 'Usuario y/o contraseña incorrectos',
        'auth/invalid-email': 'Email no válido',
        'auth/all-fields-mandatory': 'Todos los campos son obligatorios',
        'auth/too-many-requests': 'Cuenta deshabilitada por seguridad. Intente de nuevo más tarde'
    }

    const handleLogin = async (event) => { 
        event.preventDefault();
        if (!formData.email || !formData.password) {
            setErrorMessage('auth/all-fields-mandatory');
            return;
        }

        const {success , error } = await login(formData.email, formData.password);
            
            if (success) {
                history.push('/myprofile/:id');
            }else{
                setErrorMessage(error);
                }
            }    

    return(
        <LoginWrapper>
            <ImageLogin/>
    
            <FormWrapper>
                <FormLogin>
                    <ParLogin>Welcome back</ParLogin>
                    <H3Login>Login en tu cuenta</H3Login>
                    <LabelLogin>Email</LabelLogin>
                    <InputLogin
                        autocomplete="username"
                        placeholder='Email'
                        label="email"
                        name="email"
                        value={formData.email}
                        onChange={(event) => setFormData({...formData, email: event.target.value })}>
                    </InputLogin>
                    <LabelLogin>Password</LabelLogin>
                    <InputLogin
                        autocomplete="current-password"
                        type= 'password' 
                        placeholder='Contraseña'
                        label="password"
                        name="password"
                        value={formData.password}
                        onChange={(event) => setFormData({ ...formData, password: event.target.value })}>
                    </InputLogin>
                    <PasswordWrapper>
                        <Checkbox>
                            <input type="checkbox" id="RememberMe" name="RememberMe" value="RememberMe" />
                            <label style={{margin: '0px px 0px 0px'}} for="RememberMe"> Remember me</label>
                        </Checkbox>
                        <ForgotPass to='/forgot/password'>¿Contraseña olvidada?</ForgotPass>
                    </PasswordWrapper>
                    
                    <ButtonLogin onClick={handleLogin}>Log in</ButtonLogin>
                    <ButtonGoogle><img src={GoogleIcon} style={{margin:' 0px 10px 0px 0px'}} alt='Google icon'/>Log in with Google</ButtonGoogle>
                    <NotMember>¿No eres miembro? <NotMemberLink to="/signup">Sign up aquí</NotMemberLink></NotMember>
                    <ErrorMessage>{errorMessage !== '' ?  ERROR_MESSAGES[errorMessage] : ''}</ErrorMessage>
                </FormLogin>

            </FormWrapper>
            
        </LoginWrapper>
    )
};

export default LoginPage;