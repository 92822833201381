import styled from 'styled-components';
import { RiHeart3Line, RiHeart3Fill} from 'react-icons/ri';
import { mediumgrey, primaryColor} from '../config/theme';

export const HeartWrapper = styled.div`
`

export const HeartIconFull = styled(RiHeart3Fill)`
    color: ${props => primaryColor};
    width: 30px;
    height: 30px;
    margin: 0px 3px;
    &:hover{
        color: ${props => mediumgrey};
    }
`
export const HeartIconEmpty = styled(RiHeart3Line)`
    color: ${props => mediumgrey};
    width: 30px;
    height: 30px;
    margin: 0px 3px;
    &:hover{
        color: ${props => primaryColor};
    }
`