import { WeekPageWrapper, WeekTitle, WeekWrapper, DayTitle, DayWrapper, SnackWrapper, MealWrapper } from "./layouts/weekPageLayout";

function WeekPage() {
    return(
        <WeekPageWrapper>
            <WeekTitle>Semana 8</WeekTitle>

            <WeekWrapper>
                <DayWrapper>
                    <DayTitle>Lunes</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Martes</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Miércoles</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Jueves</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Viernes</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Sábado</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>

                <DayWrapper>
                    <DayTitle>Domingo</DayTitle>
                    <SnackWrapper></SnackWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                    <SnackWrapper></SnackWrapper>
                    <MealWrapper></MealWrapper>
                </DayWrapper>
            </WeekWrapper>
        </WeekPageWrapper>
    )
};

export default WeekPage;
