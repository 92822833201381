

function ShoppingListPage() {

    return(
      <div>Holla Shopping list</div>
    )
    
};

export default ShoppingListPage;