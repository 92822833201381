import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUser, clearUser } from './redux/user/userActions';
import Routes from './routes';
import { registerAuthObserver } from './services/auth';
import { getUserProfile } from './controllers/user';

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    registerAuthObserver(async (user) => {
      if (user) {
        console.log('IMTCHLG ~ El usuario ha hecho login: ', user);
        dispatch(setUser({ id: user.uid }));
        const userProfile = await getUserProfile(user.uid);
        // setUserData({ uid: user.uid });
        dispatch(setUser(userProfile));
      } else {
        console.log('IMTCHLG ~ El usuario ha hecho logout: ');
        // setUserData(null);
        dispatch(clearUser());
      }
      setIsLoading(false)
    })
  }, []);

  if (isLoading) return <>Cargando...</>;

  return (
    <div className="app">
      <Routes userData={userData}/>
    </div>
  );
}

export default App;