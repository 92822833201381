import { combineReducers,createStore } from 'redux';
import { Provider } from 'react-redux';
import userReducer from './user/userReducer';

const reducers = combineReducers({
  user: userReducer
})

const store = createStore(
  reducers,
  window.REDUX_DEVTOOLS_EXTENSION ? [window.REDUX_DEVTOOLS_EXTENSION()] : []
);

const ReduxProvider = ({ children }) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default ReduxProvider;
