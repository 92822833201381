import styled from 'styled-components';
import { lightgrey, primaryColor, white ,mediumgrey, lightgreen, secondaryColor} from '../../components/config/theme';
import RecipePic from '../../components/assets/RecipePic.png';
import Avatar from '../../components/assets/Avatar.png';
import {BiShareAlt} from 'react-icons/bi';
import { Link } from 'react-router-dom';

export const RecipeBody = styled.div`
display: flex;
align-items: center;
`
export const LinkProfile = styled(Link)`
    
`

export const Background = styled.div`
    width: 100%;
    height: 100%;
    /*position: absolute;
    top: 0;
    left: 0;*/
    position: fixed; 
    top: -50%; 
    left: -50%; 
    width: 200%; 
    height: 200%; 
    display: flex;
    flex-direction: column;
    z-index: -1;
`

export const RecipeBackgroundGrey = styled.div`
    flex: 2;
    background-color: ${props => lightgrey};
`
export const RecipeBackgroundGreen = styled.div`
    flex: 3;
    background-color: ${props => primaryColor};
`
export const RecipeWrapper = styled.div`
    width: 80vw;
    background-color: ${props =>lightgreen};
    z-index:98;
    position: relative;
    margin: 3% 0px; 
    left: 10%;
    display: flex;
    flex-direction: column;
    

    @media screen and (max-width:768px){
        width: 100%;
        display:flex;
        flex-direction: column;
        left: 0;
        margin: 0px 0px;
    }
`
export const RecipeInfo = styled.div`
    display: flex;
    justify-content: space-around;
    justify-content: stretch;

    @media screen and (max-width:768px){
        flex-direction: column-reverse;
        width: 100%;
    }
`
export const TextWrapper = styled.div`
    width: 60%;
    height: 100%;
    background: white;
    padding: 3em 3em 1em 3em;

    @media screen and (max-width:768px){
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
    }
`
export const ImageWrapper = styled.div`
    width: 60%;
    /* background: url(${props => RecipePic}) no-repeat center /cover; */
    @media screen and (max-width:768px){
        margin-top: 0px;
        background: url(${props => RecipePic}) no-repeat center /cover;
        width: 100%;
        height: 40vh;
    }
`
export const AuthorInfo = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width:768px){
        justify-content: center;
        box-sizing: border-box;
    }
`
export const PicAuthor = styled.div`
    width: 100px;
    height: 100px;
    background: url(${props => Avatar}) no-repeat center /cover;
    border-radius: 100%;
`
export const NameAuthor = styled.h3`
    padding: 2em;
    text-transform: uppercase;
`
export const Category = styled.p`
    font-size: 1.2em;
    font-weight: 700;
    color: ${props => secondaryColor};
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 5px;
   
`
export const RecipeTitle = styled.p`
    font-size: 2em;
    margin: 10px 0px;
`
export const Description = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:768px){
        width: 100%;
        flex-direction: column;
    }
`
export const DescriptionText = styled.p`
    width: 60%;
    text-justify: inter-word;
    @media screen and (max-width:768px){
        width: 90%;
    }
`
export const Linea = styled.hr`
    margin: 3px 0px;
    border: 0.1px solid ${props => lightgrey};
`
export const TimeRecipe = styled.div`
`
export const TimesP = styled.p`
`
export const CategoryWrapper = styled.div`
 @media screen and (max-width:768px){
        padding-top: 1em;
        display: flex;
        flex-wrap: wrap;
        width: 90%;
    }
`
export const CategoryButton = styled.button`
    border-radius: 30px;
    border: none;
    padding: 15px 20px;
    margin:  5px 5px;
    font-size: 0.9em;
    font-style: italic;
    color: ${props => secondaryColor};
`
export const LastWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    @media screen and (max-width:768px){
        display: flex;
        flex-wrap: wrap;
        width: 80%;
    }
`
export const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const ShareIcon = styled(BiShareAlt)`
    width: 30px;
    height: 30px;
    color: ${props => mediumgrey};
    margin: 0px 3px;
    &:hover{
        color: ${props => primaryColor};
    }
`

export const InstructionsWrapper = styled.div`
` 

export const RecipeInstructions = styled.div`
    background-color: ${props => lightgreen};
    padding: 1em 3em 3em 3em;
    width: 50%;
    text-align: justify;
    
    @media screen and (max-width:768px){
        width: 100%;
        display: flex;
        flex-direction:column;
        align-items:center;
        box-sizing: border-box;
    }
`

export const ImgDescription = styled.img`
    width: 250px;
    height: 250px;
    margin: 30px 15px;
    background-image: url(${props => RecipePic}) no-repeat center /cover;
`
export const IngredientsWrapper = styled.div`
    width: 35%;
    background-color: ${props => white};
    position: absolute;
    right: 6%;
    top: 32%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-self: flex-start;
    padding: 0.5em 2em 2em 2em;

    @media screen and (max-width:768px){
        background-color: ${props => white};
        position: relative;
        right: 0%;
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 1em 3em 3em 3em;
    }
`
export const IngredientP = styled.p`
`