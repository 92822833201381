import styled from 'styled-components'; 
import { lightgrey, mediumgrey, primaryColor, secondaryColor, white } from '../config/theme';
import {IoCloseOutline} from "react-icons/io5";
import Avatar from '../assets/Avatar.png';
import { LabelLogin, InputLogin } from '../../pages/layouts/loginlayout';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    position: fixed;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:768px){
        align-items: flex-start;
        justify-content: flex-start;
    }
`

export const ModalWrapper = styled.div`
    width: 60vw;
    //height: 70vh;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background-color: ${props => lightgrey};
    color: ${props => mediumgrey};
    display: flex;
    justify-content: space-around;
    position: relative;
    /*margin-top: 12%;
    top: 25%;
    left: 20%;*/
    z-index: 21;
    border-radius: 4px;
    padding: 30px;

    @media screen and (max-width:768px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        margin: 5vw;
    }
`

export const FormNewRecipe = styled.form`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width:768px){
        width: 90%;
    }
`


export const IconClose = styled(IoCloseOutline)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`

export const PicAuthor = styled.div`
    height: 150px;
    width: 150px;
    background: url(${props => Avatar}) no-repeat center /cover;
    border-radius: 50%;
    margin: 30px 30px 30px 5px;
    box-sizing: border-box;
    flex: 0 0 150px;
    @media screen and (max-width:768px){
        margin: 20px 5px;
    }
`

export const RecipeInput = styled(InputLogin)`
    color: ${props => primaryColor};
    margin: 5px 0px;
    padding-left: 5px;
    box-sizing: border-box;
    @media screen and (max-width:768px){
        font-size: 0.7em;
    }
`

export const FileInput = styled.input`
    padding: 20px 0px;
    box-sizing: border-box;
`

export const LabelNewRecipe = styled(LabelLogin)`
    font-size: 14px;
    color: ${props => secondaryColor};
    @media screen and (max-width:768px){
        font-size: 1em;
    }
`

export const HorizontalNewRecipe = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;

    @media screen and (max-width:768px){
        margin: 0;
    }
`

export const TextArea = styled.textarea`
    width: 100%;
    height: 30%;
    
    border: none;
    background-color: ${props => white};
    padding-left: 10px;
    box-sizing: border-box;
    

    &:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
    @media screen and (max-width:768px){
        height: 200px;
        margin:10px 0;
        padding:0;
    }
`
export const InputSubmit = styled.input`
    background: ${props => primaryColor};
    border: none;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    color: ${props => white};
    font-size: 1em;
    font-weight: 700;
`

export const CategoryWrapper= styled.div` 
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
`