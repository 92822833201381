import styled from 'styled-components';
import { lightgreen, lightgrey, mediumgrey, primaryColor, secondaryColor, white } from '../../components/config/theme';

export const WeekPageWrapper = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 20px auto;
    justify-content: stretch;
`
export const WeekTitle = styled.div`
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${props => primaryColor};
    font-size: 2em;
    font-weight: 700;
    color: ${props => white};
`

export const WeekWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 80vh ;

    @media screen and (max-width:768px){
        flex-direction: column;
        height: 100vh;
    } 
`

export const DayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => lightgrey};
    flex:1;
    @media screen and (max-width:768px){
        height: 300px;
    }
`
export const DayTitle = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${props => secondaryColor};
    border: 1px solid ${props => white};
    font-size: 1em;
    color: ${props => white};
`
export const SnackWrapper = styled.div` 
     border: solid 1px ${props => white};
     flex:1;
     
     background-color: ${props => lightgrey};
     @media screen and (max-width:768px){
        height: 200px;
    }
`
export const MealWrapper = styled.div` 
    background-color: ${props => lightgreen};
     border: solid 1px ${props => white};
     flex:2;
     
     @media screen and (max-width:768px){
        height: 200px;
    }
`

/* export const Breakfast = styled.div`

`

export const WeekWrapper = styled.div`

`

export const WeekWrapper = styled.div`

`

export const WeekWrapper = styled.div`

`

export const WeekWrapper = styled.div`

` */
