import { IoExitOutline } from "react-icons/io5";
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { darkenPrimary, primaryColor } from './config/theme';
import { logout } from '../services/auth';

const LogoutIcon = styled(IoExitOutline)`
    width: 27px;
    height: 27px;
    color: ${ props => primaryColor};
    cursor: pointer;


    &:hover{
        color: ${ props => darkenPrimary};
    }

    @media screen and (max-width:768px){
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
    }
`

const Logout = () => {
    const history = useHistory()

    async function handleLogout(){
    
        try{
            await logout()
            history.push('/login')
        } catch {
            //setError('Failed to log out')
        }
    }
    return <LogoutIcon onClick= {handleLogout}/>
}

export default Logout;