import styled from 'styled-components';
import Avatar from '../../components/assets/Avatar.jpg';
import { lightgreen,primaryColor,white} from '../../components/config/theme';
import { Link } from 'react-router-dom';
import { BsPlusSquare } from 'react-icons/bs';


export const ProfileHeader = styled.div`
    width: 100%;
    //height: 17vw;
    background-color:${props => primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
 `

export const ProfileWrapper = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width:768px){
        flex-direction: column;
    }
`
export const PicAuthor = styled.div`
    height: 150px;
    width: 150px;
    background: url(${props => Avatar}) no-repeat center /cover;
    border-radius: 50%;
    margin: 30px 30px 30px 5px;
    box-sizing: border-box;
    flex: 0 0 150px;
    @media screen and (max-width:768px){
        margin: 20px 5px;
    }
`
export const InfoProfile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width:768px){
        align-items: center;
    }
 `
export const H3Profile = styled.h1`
    color: ${props => white};
    margin: 0px;
    letter-spacing: 1px;
`
export const ParProfile = styled.p`
    margin-top: 0px;
    @media screen and (max-width:768px){
     text-align: center;   
    }
`
export const ButtonWrapperProfile = styled.div`
    display: flex;
    align-items: center;
 `
export const EditProfileButton = styled(Link)`
    border: 2px solid ${props => lightgreen};
    padding: 10px 15px;
    margin: 0px 5px;
    border-radius:4px;
    background-color: ${props => primaryColor};
    color: ${props => lightgreen};
    font-weight: 700;
    text-decoration: none;
    
    &:hover{
        color:${props => white}; 
        border: 2px solid ${props => white};
        cursor: pointer;
    }

    @media screen and (max-width:768px){
        margin: 10px 5px;
        padding: 5px 7px;
    }
`
export const CreateNewRecipe = styled(BsPlusSquare)`
    color:${props => lightgreen};
    width: 35px;
    height: 35px;
    margin-left: 10px;
    cursor: pointer;

    &:hover{
        color:${props => white}; 
    }
 `

