import { useEffect, useState, } from 'react';
import { listCollection } from '../../services/db';
import Button from '../button';
import { UpperCardWrapper, CardWrapper, CardImage, TextWrapper, H3text, Category, DescriptionWrapper, ParDescription, ShareIcon, ButtonWrapper, IconWrapper } from './CardElements';
import HeartIcon from '../HeartIcon';

function Card() {

    const [recipes,setRecipes]= useState([]);
    const getRecipes = async () => {
        const result = await listCollection('recipes');
        const {data,success} = result;
        if(success){
            setRecipes(data);
        }
    }

    useEffect(()=> {getRecipes()},[])
    
    return(
        <UpperCardWrapper>
            {recipes.map((recipe)=>{
                
                const {description,name,pic,id,category} = recipe;
                
                return(
                    <CardWrapper key={id}>
                        <CardImage alt="pic" src={pic}/>
                        <TextWrapper>
                            <H3text>{name}</H3text>
                            <Category>{category[0]}</Category>
                            <DescriptionWrapper>
                                <ParDescription>{description}</ParDescription>
                                </DescriptionWrapper>
                            <ButtonWrapper>
                                <Button to={`/recipe/${id}`}>Ver receta</Button>
                                <IconWrapper>
                                    <HeartIcon recipeId={id}/>
                                    <ShareIcon> </ShareIcon>
                                </IconWrapper>
                            </ButtonWrapper>
                            
                        </TextWrapper>
                    </CardWrapper>
            )} )}
        </UpperCardWrapper>
    )
};

export default Card;
