import { useEffect, useState, } from 'react';
import { listCollection } from '../../services/db';
import Button from '../button';
import { useSelector } from 'react-redux';
import { UpperCardWrapper, CardWrapper, CardImage, TextWrapper, H3text, Category, DescriptionWrapper, ParDescription, ShareIcon, ButtonWrapper, IconWrapper } from '../Card/CardElements';
import HeartIcon from '../HeartIcon';

const FavoritesCard = () =>{
    
    const user = useSelector(state => state.user);
    const userFavs = user.fav_recipes
    console.log('soy las favs', userFavs);
    console.log('del user', user);
    
    const [recipes,setRecipes]= useState([]);
    const getRecipes = async () => {
        const result = await listCollection('recipes');
        const {data,success} = result;
        if(success){
            setRecipes(data);
        }
    }

    useEffect(()=> {getRecipes()},[])
    
    return(
        <UpperCardWrapper>


            {userFavs.map((fav)=>{
                const {description,name,pic,id,cat} = fav;
                return(
                    <CardWrapper key={id}>
                        <CardImage alt="pic" src={pic}/>
                        <TextWrapper>
                            <H3text>{name}</H3text>
                            <Category>{cat[0]}</Category>
                            <DescriptionWrapper>
                                 <ParDescription>{description}</ParDescription>
                                </DescriptionWrapper>
                            <ButtonWrapper>
                                <Button to={`/recipe/${id}`}>Ver receta</Button>
                                <IconWrapper>
                                    <HeartIcon recipeId={id}/>
                                     <ShareIcon> </ShareIcon>
                                    </IconWrapper>
                            </ButtonWrapper>
                                        
                        </TextWrapper>
                    </CardWrapper>
                )
             })}
                    
        </UpperCardWrapper>
                )
            };

export default FavoritesCard;
