import React , { useState } from 'react';
import { ModalWrapper, IconClose, Background, RecipeInput, LabelNewRecipe, FormNewRecipe, HorizontalNewRecipe, TextArea,InputSubmit, FileInput} from './modalElementsNewRecipe';
import { Category, CategoryWrapper } from '../../pages/layouts/recipelayout';
import { useEffect, useRef, useCallback } from 'react';
import {useSpring, animated} from 'react-spring';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createObject } from '../../services/db';
import Button from '../button';
import firebase from "firebase";


export const ModalNewRecipe = ({showModalNewRecipe, setShowModalNewRecipe}) => {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [formData, setFormData] = useState({ name: '', author_id: user.id, description:'', dificulty:'', elaboration:'', cook:'', instructions:'',ingredients:[],category:[]});
    const [newIngredient, setNewIngredient] = useState ({quantity:'', name:'', measure:''});
    const [uploadValue, setUploadValue] = useState(-1);
    const [file, setFile] = useState("");

    const modalRefNewRecipe = useRef()
    const closeModalNewRecipe = (e) =>{
        if (modalRefNewRecipe.current === e.target){
            setShowModalNewRecipe(false);
        }
    }

    const animationNewRecipe = useSpring({
        config:{
            duration: 250
        },
        opacity: showModalNewRecipe ? 1 : 0,
        transform: showModalNewRecipe ? `translateY(0%)` : `translateY(-100%)`
    })

    const keyPress = useCallback(
        e => {
          if (e.key === 'Escape' && showModalNewRecipe) {
            setShowModalNewRecipe(false);
            console.log('I pressed');
          }
        },
        [setShowModalNewRecipe, showModalNewRecipe]
      );
    
      useEffect(
        () => {
          document.addEventListener('keydown', keyPress);
          return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
      );


    const handleCreateNewRecipe = async (event) => {
        event.preventDefault();
        console.log('Soy la data',formData);
        
        const newFormData = {
            ...formData
        }

         const result = await createObject('recipes',newFormData);
        
        
        if (result) {
            alert('Se ha creado una nueva receta');
            history.push('/profile/:id')
        }else{
            console.log('La receta no se ha creado correctamente');
        }
    } 
        
    
    const handleAddIngredient = (event) => {
        event.preventDefault();

        const newFormData = {
            ...formData,
            ingredients: [
                ...formData.ingredients, newIngredient
            ]
        }

        setFormData(newFormData);
        setNewIngredient({quantity:'', name:'', measure:''})
    }

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const storageRef = firebase
          .storage()
          .ref(`/images/${formData.name}`);
        const task = storageRef.put(file);
        task.on(
          "state_changed",
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + percentage + "% done");
            setUploadValue(percentage);
            // eslint-disable-next-line default-case
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            console.log(error.message);
          },
          async () => {
            setUploadValue(100);
            const fileURL = await task.snapshot.ref.getDownloadURL();
            setFile(fileURL);
            console.log(fileURL);
            setUploadValue(-1);
            setFormData({ ...formData, pic: fileURL });
          }
        );
      };



    return (


        <>
        {showModalNewRecipe 
        ? (
            <Background  ref={modalRefNewRecipe} onClick={closeModalNewRecipe}>
                <animated.div style={animationNewRecipe}>
                    <ModalWrapper showModal={showModalNewRecipe}>
                        <IconClose aria-label='close modal new recipe' onClick={()=>setShowModalNewRecipe(prev => !prev)} />
                        
                            <FormNewRecipe onSubmit={handleCreateNewRecipe}>
                            <Category >Crea una receta nueva</Category>
                            <HorizontalNewRecipe style={{marginTop:`10px`}}>
                            <LabelNewRecipe>Dificultad:</LabelNewRecipe>
                                <div><span>Fácil</span><input type="radio" name="dificulty" value="Fácil"/></div>
                                <div><span>Media</span><input type="radio" name="dificulty" value="Media"/></div>
                                <div><span>Difícil</span><input type="radio" name="dificulty" value="Difícil"/></div>
                            </HorizontalNewRecipe>
                            <RecipeInput
                                placeholder='Título de la receta'
                                label="name"
                                name="name"
                                value={formData.name}
                                onChange={(event) => setFormData({ ...formData, name: event.target.value })}
                                >
                            </RecipeInput>

                            <RecipeInput
                                placeholder='Descripción breve de la receta'
                                label="description"
                                name="description"
                                value={formData.description}
                                onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                                >
                            </RecipeInput>
                            
                            

                            <HorizontalNewRecipe>
                                <RecipeInput
                                    type='number'
                                    placeholder='Tiempo de elaboración'
                                    label="elaboration"
                                    name="elaboration"
                                    value={formData.elaboration}
                                    onChange={(event) => setFormData({ ...formData, elaboration: event.target.value })}
                                    >
                                </RecipeInput>
                                <RecipeInput style={{margin:` 0 0 0 5px`}}
                                    type='number'
                                    placeholder='Tiempo de cocción'
                                    label="cook"
                                    name="cook"
                                    value={formData.cook}
                                    onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                                    >
                                </RecipeInput>
                            </HorizontalNewRecipe>
                            
                            {formData.ingredients.map(ingredient => {
                                    return(
                                        <div>{ingredient.name}{ingredient.quantity}{ingredient.measure}</div>
                                    )
                                })}

                            <HorizontalNewRecipe>
                            <RecipeInput
                                    style={{flex:3}}
                                    type='text'
                                    placeholder='Ingrediente'
                                    name="ingredient"
                                    value={newIngredient.name}
                                    onChange={(event) => setNewIngredient({ ...newIngredient, name: event.target.value })}
                                    >
                                </RecipeInput>
                                <RecipeInput style={{margin:` 0 0 0 5px`, flex:1}}
                                    type='number'
                                    placeholder='Cantidad'
                                    name="quantity"
                                    value={newIngredient.quantity}
                                    onChange={(event) => setNewIngredient({ ...newIngredient, quantity: event.target.value })}
                                    ></RecipeInput>
                                 <RecipeInput style={{margin:` 0 0 0 5px`, flex:1}}
                                    type='text'
                                    placeholder='Unidad'name="measure"
                                    value={newIngredient.measure}
                                    onChange={(event) => setNewIngredient({ ...newIngredient, measure: event.target.value })}
                                    ></RecipeInput>

                                <Button style={{boxSizing:`border-box`, padding:`4px 10px`, marginLeft:`3px`}} onClick={handleAddIngredient}>+</Button>
                            </HorizontalNewRecipe>
    

                            
                            </FormNewRecipe>

                            <FormNewRecipe>
                                
                                <CategoryWrapper style={{marginTop:`10px`}}>
                                <LabelNewRecipe>Categoria de tu receta</LabelNewRecipe>
                                <div>Cocina Mediterránea<input type="checkbox" id="category" name="category" value="Cocina mediterránea"/></div>
                                <div>Cocina Asiática<input type="checkbox" id="category" name="category" value="Cocina asiática"/></div>
                                <div>Cocina Vegana<input type="checkbox" id="category" name="category" value="Cocina vegana"/></div>
                                <div>Ensaladas<input type="checkbox" id="category" name="category" value="Ensaladas"/></div>
                                <div>Saludable<input type="checkbox" id="category" name="category" value="Saludable"/></div>
                                <div>Mejor en invierno<input type="checkbox" id="category" name="category" value="Mejor en invierno"/></div>
                                <div>Mejor en verano<input type="checkbox" id="category" name="category" value="Mejor en verano"/></div>
                            </CategoryWrapper>

                                <p>Sube una foto de tu receta!</p>
                                <FileInput
                                type="file"
                                label="Upload file"
                                name="image"
                                onChange={(file) => handleUpload(file)}
                                />
                                

                                <TextArea
                                    type ='textarea'
                                    placeholder='Instrucciones'
                                    label="description"
                                    name="description"
                                    name="instructions"
                                    value={newIngredient.instructions}
                                    onChange={(event) => setFormData({ ...formData, instructions: event.target.value })}
                                    >
                                </TextArea>
                                <InputSubmit type="submit" onClick={handleCreateNewRecipe}/>
                            </FormNewRecipe>
                    </ModalWrapper>
                </animated.div>
            </Background>
        )
        : null}
        </>
    )
};

export default ModalNewRecipe;