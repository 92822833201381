import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import logo from '../assets/5.png';
import { logout } from '../../services/auth';
import { 
    Nav,
    LeftMenu,
    RightMenu, 
    NavLink,
    Bars, 
    NavMenu, 
    NavBtn,
    MenuNavLink,
    UserIcon
} from './NavbarElements';
import SearchBarUpperMenu, {SearchBarDownMenu} from '../SearchBar.js';
import Logout from '../Logout';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const user = useSelector((state) => state.user);
   
    return (
        <div>
            <Nav>
                <LeftMenu >
                    <NavLink to="/">
                        <img src={logo} alt="Logo makemyweek" width='50px'/>
                    </NavLink>

                    <Bars onClick={()=>setShowMenu(!showMenu)}/>
                    <NavMenu showMenu={showMenu} >
                        <MenuNavLink onClick={()=>setShowMenu(!showMenu)} to="/myprofile/:id" >
                            Profile 
                        </MenuNavLink>
                        <MenuNavLink onClick={()=>setShowMenu(!showMenu)} to="/myweek/:id" >
                            Your Week
                        </MenuNavLink>
                        <MenuNavLink onClick={()=>setShowMenu(!showMenu)} to="/listacompra/:id" >
                            Shopping List 
                        </MenuNavLink>
                        
                    </NavMenu>
                </LeftMenu>

                <RightMenu>
                    <SearchBarUpperMenu />
                    <NavBtn>

                         {user 
                            ? <Logout onClick={()=>logout()}/>
                            : <Link to="/login"><UserIcon/></Link>
                        }

                    </NavBtn>
                </RightMenu>
                
            </Nav>
            <SearchBarDownMenu />
         </div>   
    )
    
}

export default Navbar;
