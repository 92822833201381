
/********************************
             Colors
*********************************/
 export const primaryColor = '#75a19b';
 export const secondaryColor = '#313e42';
 export const lightgrey = '#eceeee';
 export const mediumgrey = '#858C94';
 export const white = '#ffffff';
 export const darkenPrimary = '#56756E';
 export const lightgreen = '#D2E5E2';
 
 